<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 18 18"
	enable-background="new 0 0 18 18"
	xml:space="preserve"
	class={$$restProps.class || ''}
>
	<g>
		<path
			fill="currentColor"
			d="M17.003,15.5l-3.46-3.46c0.792-1.138,1.259-2.518,1.259-4.009C14.802,4.148,11.654,1,7.771,1
		C3.888,1,0.74,4.148,0.74,8.031s3.148,7.031,7.031,7.031c1.758,0,3.361-0.649,4.593-1.715l3.396,3.396
		c0.343,0.343,0.899,0.343,1.242,0l0.001-0.001C17.346,16.399,17.346,15.843,17.003,15.5z M7.771,13.304
		c-2.912,0-5.273-2.361-5.273-5.273s2.361-5.273,5.273-5.273c2.912,0,5.273,2.361,5.273,5.273S10.683,13.304,7.771,13.304z"
		/>
	</g>
</svg>
